import React, { createContext, useContext, useState } from "react";
import { FilterTabContent } from "@/app/dashboard/conversations/utils/constants";
import { GetEndUsersResult, User } from "@/app/dashboard/fetchers/get-end-users";
import { EndUser } from "@/app/subscriptions/end-user-subscription";

interface UserContextProps {
  users: GetEndUsersResult | undefined;
  page: number;
  hasMore: boolean;
  selectedUser: User | undefined;
  selectedTab: FilterTabContent | undefined;
  selectedOpenStatus: "open" | "closed" | undefined;
  newEndUser: EndUser | undefined;
  setUsers: React.Dispatch<React.SetStateAction<GetEndUsersResult | undefined>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<FilterTabContent | undefined>>;
  setSelectedOpenStatus: React.Dispatch<React.SetStateAction<"open" | "closed" | undefined>>;
  setNewEndUser: React.Dispatch<React.SetStateAction<EndUser | undefined>>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<GetEndUsersResult | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [selectedTab, setSelectedTab] = useState<FilterTabContent | undefined>();
  const [selectedOpenStatus, setSelectedOpenStatus] = useState<"open" | "closed" | undefined>();
  const [newEndUser, setNewEndUser] = useState<EndUser | undefined>();

  return (
    <UserContext.Provider value={{ users, setUsers, page, setPage, hasMore, setHasMore, selectedUser, setSelectedUser, selectedTab, setSelectedTab, selectedOpenStatus, setSelectedOpenStatus, newEndUser, setNewEndUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
